import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsFillCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { IMetadataOption } from '../../../../entities/metadata.entity';
import { RootState } from '../../../../redux/store';


export const getTriennio = (id: number, list: Array<IMetadataOption>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getTriennioOption = (id: number, list: Array<IMetadataOption>): IMetadataOption | null => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  onSelect?: (option: IMetadataOption | null) => void;
  excludeIds?: Array<number>;
}

const Triennio: FC<IProps> = (props) => {

  const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
  const [excludes, setExcludes] = useState<Array<number>>(props?.excludeIds && props.excludeIds.length > 0 ? props.excludeIds : new Array<number>());

  const defaultSelectedOption = (): IMetadataOption | null => {
    if (props?.defaultSelectedId) {
      return getTriennioOption(props.defaultSelectedId, trienni);
    }
    return null;
  };

  const [filterByTriennio, setFilterByTriennio] = useState<IMetadataOption | null>(defaultSelectedOption());

  const handleFilterByTriennio = (option: IMetadataOption | null) => {
    setFilterByTriennio(option);
    if (props?.onSelect) {
      props.onSelect(option);
    }
  };

  return (
    <Fragment>
      <Dropdown className="mx-1">
        <Dropdown.Toggle variant="outline-primary" id="dropdown-triennio">
          {filterByTriennio != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-dark" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Triennio ${filterByTriennio ? filterByTriennio.nome : ''} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <Dropdown.Item key={"dropdownTriennioNone"} disabled={filterByTriennio == null} onClick={() => handleFilterByTriennio(null)}>Tutti</Dropdown.Item>
          {
            trienni.length ? (
              trienni.filter((itm: IMetadataOption) => !excludes.includes(itm.id)).map((item: IMetadataOption, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownTriennio" + item.id} disabled={filterByTriennio != null && item.id === filterByTriennio.id} onClick={() => handleFilterByTriennio(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default Triennio;