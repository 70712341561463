import { FC, Fragment, useState } from 'react';
import { Alert, Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { IPostProfessionalCertificateImportThunkPayload, postProfessionalCertificateImport } from '../../redux/slices/professionals.slice';
import { AppDispatch } from '../../redux/store';

interface IForm extends IPostProfessionalCertificateImportThunkPayload { }

const AdminImportProfessional: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [fields, setFields] = useState({
        fileName: '',
        contentType: '',
        contentRaw: '',
    } as IForm);

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;

        if (files.length) {
            const file_name = files[0].name;
            const file_type = files[0].type;

            const fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target?.result) {
                    const res = e.target.result;
                    setFields({
                        fileName: file_name,
                        contentType: file_type,
                        contentRaw: res,
                    });
                }
            };

            fileReader.readAsDataURL(files[0]);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //setHasError(false);
        setLoading(true);

        dispatch(postProfessionalCertificateImport(fields as IPostProfessionalCertificateImportThunkPayload))
            .unwrap()
            .then(() => setSubmitted(true))
            .catch(() => setSubmitted(true))
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            <PageTitle content="Import" />
            <PageSubtitle content="Importa lista certificazioni/esoneri" />
            <PageParagraph content="" />
            <Container fluid as="section">
                <Card className="mt-3">
                    <Card.Body>
                        {submitted ? (
                            <div className="text-center">
                                <CheckCircleFill size={100} className="text-success m-5" />
                                <h3 className="text-success">Operazione completata!</h3>
                            </div>
                        ) : (
                            <Form noValidate onSubmit={handleSubmit} >
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>
                                        {/* <p><b>Si raccomanda di NON modificare il file ottenuto dalla piattaforma di esportazione</b></p>
                                        <p>State per caricare i dati delle Certificazioni e degli Esoneri: la sezione per la visualizzazione dello stato formativo è in
                                            costruzione e i dati saranno visibili a partire dal 26 Aprile 2022.</p> */}
                                        <p><b>Funzione temporaneamente disabilitata per dare modo di acquisire i nuovi codici da impiegare.</b></p>
                                        {/* <br /><br />File da importare */}
                                    </Form.Label>
                                    {/* <Form.Control type="file" name="file" onChange={handleFileUpload} /> */}
                                </Form.Group>
                                {/* <Button type="submit" className="w-25 float-right" disabled={isLoading}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendere</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Salva</span>
                                    )}
                                </Button> */}
                                {hasError ? (
                                    <Fragment>
                                        <Alert variant="danger">
                                            <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                                            <p>Il file è stato salvato correttamente nei nostri sistemi. La lettura del file non ha avuto successo!</p>
                                        </Alert>
                                    </Fragment>
                                ) : (
                                    <Fragment />
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </Fragment>
    );
};

export default AdminImportProfessional;
