import { Formik } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IRejectResponse } from '../../api/api.interface';
import { ICertificationEntity } from '../../entities/certification.entity';
import { ICompanyEntity } from '../../entities/company.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { IPutCertificationThunkPayload, putCertification } from '../../redux/slices/certification.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { parseISOStringToStandardDate } from '../../utils/course-parser';
import { getNome } from './dropdown/certification/OggettoTipologia';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';
import ShowDetailAttachment from './ShowDetailAttachment';
import AddAttachForm from './AddAttachForm';

interface IProps {
  certification: ICertificationEntity;
  submitted: boolean;
}

const CertificationEdit: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [initialValues,] = useState<ICertificationEntity>({ ...props.certification, dataRiferimentoDa: parseISOStringToStandardDate(props.certification.dataRiferimentoDa), dataRiferimentoA: parseISOStringToStandardDate(props.certification.dataRiferimentoA) });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<IRejectResponse>();
  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const oggettiCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione);
  
  // CONTROLLARE se serve active = 0 oppure active = 1
  const accrediti: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione);
  const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione);
  const esoneriAll: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione_all);

  // const tipologieCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipologia_certificazione);
  const staticertificazione: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);

  const [idTipologia, setIdTipologia] = useState<number>(0);
  const [nomeTipologia, setNomeTipologia] = useState<string>("");

  const [showAddAttachModal, setShowAddAttachModal] = useState<boolean>(false);
  const handleOpenAddAttachModal = () => setShowAddAttachModal(true);
  const handleCloseAddAttachModal = () => {
    setShowAddAttachModal(false);  
  };

  const [allegatiAggiornati, setAllegatiAggiornati] = useState(props.certification.allegati ? props.certification.allegati : []);
  // useEffect(() => {
  //   console.log("allegati aggiornati!!");
  //   console.log(allegatiAggiornati);
  // }, [allegatiAggiornati]);

  useEffect(() => {
    if ((props.certification.idOggetto === 1 || props.certification.idOggetto === 5)) {
      if (accrediti.length) {
        setIdTipologia(props.certification.tipoAccreditoCfpId);
        setNomeTipologia(getNome(props.certification.tipoAccreditoCfpId, accrediti));
      }
    } else {
      if (esoneriAll.length) {
        // if (esoneri.length) {
        setIdTipologia(props.certification.tipoEsoneroId);
        setNomeTipologia(getNome(props.certification.tipoEsoneroId, esoneriAll));
        // setNomeTipologia(getNome(props.certification.tipoEsoneroId, esoneri));
      }
    }
  }, [accrediti, esoneriAll]);

  const handleSubmit = (value: ICertificationEntity) => {
    dispatch(putCertification({ certification: value } as IPutCertificationThunkPayload))
      .unwrap()
      .then(() => setSubmitted(true))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  let schema = yup.object().shape({
    idStatoCertificazione: yup.number(),
    titolo: yup.string().max(500, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
    organizzatore: yup.string().max(250, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
    codiceCorso: yup.string().max(50, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
    noteRichiedente: yup.string().max(300, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`).nullable().when("idStatoCertificazione", {
      is: 3,
      then: (schema) => schema.required("Campo obbligatorio"),
    }),
    cfpAssegnati: yup.number().required("Campo obbligatorio"),
    dataRiferimentoDa: yup.string().required("Campo obbligatorio"),
    dataRiferimentoA: yup.string().required("Campo obbligatorio"),
  });

  return (
    <Fragment>
      {submitted ? (
        <div className="text-center">
          <CheckCircleFill size={100} className="text-success mb-5" />
          <h3 className="text-success">Ben fatto!</h3>
          <p className="mt-3">Operazione completata.</p>
        </div>
      ) : (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <h6>Professionista</h6>
                  <span>{props.certification.cognome} {props.certification.nome} ({props.certification.codiceFiscale})</span>
                </Col>
                <Col md={6} sm={12}>
                  <h6>Stato richiesta</h6>
                  <Form.Control as="select" name="idStatoCertificazione" onChange={handleChange} value={values.idStatoCertificazione}>
                    {
                      (staticertificazione.length > 0) ? (
                        staticertificazione.map((item: IMetadataOption) => {
                          return (
                            <option key={"selectStatoCertificazione" + item.id} value={item.id}>
                              {item.nome}
                            </option>
                          );
                        })
                      ) : (
                        <Fragment />
                      )
                    }
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <h6>Oggetto</h6>
                  <span>{props.certification.idOggetto}. {getNome(props.certification.idOggetto, oggettiCertificazione)}</span>
                </Col>
                <Col md={3} sm={12}>
                  <h6>Periodo rif.</h6>
                  <Form.Control
                    type="date"
                    onChange={handleChange}
                    name="dataRiferimentoDa"
                    value={values.dataRiferimentoDa}
                    placeholder="Inizio"
                    isInvalid={!!touched.dataRiferimentoDa && !!errors.dataRiferimentoDa}
                  />
                  <Form.Control.Feedback type="invalid">{errors.dataRiferimentoDa}</Form.Control.Feedback>
                </Col>
                <Col md={3} sm={12}>
                  <h6>&nbsp;</h6>
                  <Form.Control
                    type="date"
                    onChange={handleChange}
                    name="dataRiferimentoA"
                    value={values.dataRiferimentoA}
                    placeholder="Fine"
                    isInvalid={!!touched.dataRiferimentoA && !!errors.dataRiferimentoA}
                  />
                  <Form.Control.Feedback type="invalid">{errors.dataRiferimentoA}</Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <h6>Tipologia</h6>
                  <span className="small">
                    {idTipologia}. {nomeTipologia}
                  </span>
                </Col>
                <Col md={3} sm={12}>
                  <h6>CFP dichiarati</h6>
                  <p>{props.certification.cfpDichiarati}</p>
                </Col>
                <Col md={3} sm={12}>
                  <h6>CFP assegnati</h6>
                  <Form.Control
                    as="input"
                    type="number"
                    min={0}
                    max={20}
                    onChange={handleChange}
                    name="cfpAssegnati"
                    value={values.cfpAssegnati}
                    isInvalid={!!touched.cfpAssegnati && !!errors.cfpAssegnati}
                    readOnly={(values.idOggetto === 2 && values.idTipologia === 0) 
                      || (values.idOggetto === 3 && values.idTipologia === 6) 
                      || (values.idOggetto === 4 && values.idTipologia === 0) 
                      || (values.idOggetto === 4 && values.idTipologia === 1)}
                  />
                  <Form.Control.Feedback type="invalid">{errors.cfpAssegnati}</Form.Control.Feedback>
                </Col>
              </Row>
              {
                (props.certification.idOggetto === 1) ? (
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <h6>Organizzatore / Titolo / Codice</h6>
                      <span className="small">
                        {(props.certification.organizzatore != null && props.certification.organizzatore.length > 0) ? props.certification.organizzatore : "--"} &nbsp; / &nbsp;
                        {(props.certification.titolo != null && props.certification.titolo.length > 0) ? props.certification.titolo : "--"} &nbsp; / &nbsp;
                        {(props.certification.codiceCorso != null && props.certification.codiceCorso.length > 0) ? props.certification.codiceCorso : "--"}
                      </span>
                    </Col>
                    <Col md={6} sm={12}>
                      <h6>Ordine</h6>
                      <span className="small">{getOrdineProvinciale(props.certification.idAzienda, companiesOrders)}</span>
                    </Col>
                  </Row>
                ) : (<Fragment />)
              }
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  {
                    (allegatiAggiornati != null && allegatiAggiornati.length > 0) ? (
                      <ShowDetailAttachment stato={props.certification.idStatoCertificazione} 
                      allegatiAggiornati={allegatiAggiornati} setAllegatiAggiornati={setAllegatiAggiornati} />
                    ) : ("Nessun allegato")
                  }
                </Col>
                <Col md={6} sm={12}>
                  <h6>Note</h6>
                  <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    name="noteRichiedente"
                    value={values.noteRichiedente}
                    isInvalid={!!touched.noteRichiedente && !!errors.noteRichiedente}
                  />
                  <Form.Control.Feedback type="invalid">{errors.noteRichiedente}</Form.Control.Feedback>
                </Col>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Col style={{textAlign: 'center'}}>
                  <Button onClick={handleOpenAddAttachModal} disabled={isLoading || (allegatiAggiornati != null && allegatiAggiornati.length > 3)}>
                    {isLoading ? (
                      <Fragment>
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <span className="ms-1">Aggiungi Allegati</span>
                        <span>...</span>
                      </Fragment>
                    ) : (
                      <span>Aggiungi Allegati</span>
                    )}
                  </Button>
                </Col>
                <Col style={{textAlign: 'right'}}>
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Fragment>
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <span className="ms-1">Attendi</span>
                        <span>...</span>
                      </Fragment>
                    ) : (
                      <span>Salva modifiche</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}

      <Modal centered show={showAddAttachModal} onHide={handleCloseAddAttachModal} >
        <Modal.Header closeButton>
            <Modal.Title><p className="display-6 m-0 p-0">Aggiungi allegato</p></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.certification ? (
            <AddAttachForm idCertification={props.certification.id} allegati={allegatiAggiornati} onTestChange={setAllegatiAggiornati}/>
            // <AddAttachForm numAllegati={allegatiAggiornati?.length ? allegatiAggiornati.length : 0} idCertification={props.certification.id} test={props.certification.allegati} onTestChange={setTest}/>
            // <AddAttachForm idCertification={props.certification.id} numAllegati={props.certification.allegati?.length ? props.certification.allegati.length : 0} test={test} onTestChange={setTest}/>
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default CertificationEdit;